<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Role</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                     
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <role-grid :key="gridReload" :deleteClick="deleteClick" :editClick="editClick"/>
                <role-form ref="roleForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import roleService from '../Role/Script/RoleServices.js';
import roleGrid from '../Role/Grid/RoleGrid.vue';
import roleForm from '../Role/Component/RoleForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Department',
    components: {
        'role-grid': roleGrid,
        'role-form': roleForm
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.roleForm.addClick();
        },
        editClick(roleData, view){
            this.$refs.roleForm.editClick(roleData, view);
        },
        deleteClick(id){
            if(id == 99999){
                this.$swal("Error", "Cannot delete this role", "error");
            }else{
                this.$swal(sweet_alert.delete).then((result) => {
                    var variables = {
                        id : id.toString()
                    }

                    if (result.isConfirmed == true) {
                        roleService.deleteQuery(variables).then(res =>{
                            this.$swal("Info", response.successDelete, "success");
                            this.reload();
                        })
                        .catch(e => {
                            var errorMessage = this.$globalfunc.errorExtractor(e)
                            this.$swal("Error", errorMessage, "error");
                        })
                    }
                });
            }
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>